import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Companyoverview extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger);
    const companiesAll = [...el.querySelectorAll('.companyoverview__company')];
    const mm = gsap.matchMedia();

    // mobile anim
    mm.add('(max-width: 768px)', () => {
      let trigger = 0;
      const triggerInterval = 75 / companiesAll.length;

      companiesAll.forEach((company) => {
        gsap.set(company, {
          y: 400,
          alpha: 0,
        });

        gsap.to(company, {
          y: 0,
          alpha: 1,
          scrollTrigger: {
            markers: false,
            scrub: true,
            trigger: el,
            start: `${trigger}% bottom`,
            end: `${trigger + triggerInterval}% 70%`,
            onEnter: () => {
              company.classList.add('companyoverview__company--active');
            },
          },
        });

        trigger += triggerInterval;
      });
    });

    // desktop anim
    mm.add('(min-width: 769px)', () => {
      let trigger = 0;
      const triggerInterval = 100 / companiesAll.length;

      companiesAll.forEach((company) => {
        gsap.to(company, {
          scrollTrigger: {
            markers: false,
            scrub: true,
            trigger: el,
            start: `${trigger}% 75%`,
            end: `${trigger + triggerInterval}% 60%`,
            onEnter: () => {
              company.classList.add('companyoverview__company--active');
            },
          },
        });

        trigger += triggerInterval;
      });
    });
  }
}

Companyoverview.className = 'Companyoverview';
export default Companyoverview;
