import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import Base from '../../_config/base';

class Scrollsmoother extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const mm = gsap.matchMedia();

    mm.add('(min-width: 769px)', () => {
      ScrollSmoother.create({
        smooth: 1,
        effects: true,
      });
    });
  }
}

Scrollsmoother.className = 'Scrollsmoother';
export default Scrollsmoother;
