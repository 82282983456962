import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Inverted extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger);
    const invTextHeight = el.querySelector('.inverted__text').clientHeight + 500;
    const headerLogo = document.querySelector('.header__logo');
    const headerMenu = document.querySelector('.hamburger');

    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        scrub: 0.5,
        delay: 0.5,
        pin: true,
        markers: false,
        start: 'top top',
        end: 'bottom+=200% 80%',
        onEnter: () => {
          headerLogo.classList.add('header__logo-invertedactive');
          headerMenu.classList.add('hamburger-icon-white');
        },
        onLeave: () => {
          headerLogo.classList.remove('header__logo-invertedactive');
          headerMenu.classList.remove('hamburger-icon-white');
        },
        onEnterBack: () => {
          headerLogo.classList.add('header__logo-invertedactive');
          headerMenu.classList.add('hamburger-icon-white');
        },
        onLeaveBack: () => {
          headerLogo.classList.remove('header__logo-invertedactive');
          headerMenu.classList.remove('hamburger-icon-white');
        },
      },
    });

    gsap.set(el.querySelector('.inverted__titel'), {
      y: 300,
      lineHeight: 2,
    });

    gsap.to(el.querySelector('.inverted__titel'), {
      y: 0,
      lineHeight: 1.3,
      scrollTrigger: {
        trigger: el,
        scrub: 0.5,
        delay: 0.5,
        markers: false,
        start: 'top 50%',
        end: 'bottom+=100% 80%',
      },
    });

    gsap.set(el.querySelector('.inverted__text'), {
      yPercent: 100,
      lineHeight: 1.6,
    });

    el.querySelector('.inverted__text').style.height = `${invTextHeight}px`;

    gsap.to(el.querySelector('.inverted__text'), {
      yPercent: 0,
      lineHeight: 1,
      scrollTrigger: {
        trigger: el,
        scrub: 0.5,
        delay: 0.5,
        markers: false,
        start: 'top top',
        end: 'bottom+=100% 80%',
        onLeave: () => {
          el.querySelector('.inverted__video').classList.add('inverted__video--active');
          el.querySelector('.inverted__text').classList.add('inverted__text--deactive');
          el.querySelector('.inverted__text').style.height = '0px';
        },
        onEnterBack: () => {
          el.querySelector('.inverted__video').classList.remove('inverted__video--active');
          el.querySelector('.inverted__text').classList.remove('inverted__text--deactive');
          el.querySelector('.inverted__text').style.height = `${invTextHeight}px`;
        },
      },
    });
  }
}

Inverted.className = 'Inverted';
export default Inverted;
