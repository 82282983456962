// eslint-disable-next-line import/no-extraneous-dependencies
import { Loader } from 'google-maps';
import Base from '../../_config/base';

class Contact extends Base {
  constructor(el) {
    super(el);
    const loader = new Loader('AIzaSyAa4RsMtqRsxuLnEyQOoSGXolKpt3T_j98');
    const SwitzerlandBounds = {
      east: 10.49234,
      north: 47.808455,
      south: 45.81792,
      west: 5.95608,
    };

    // load google maps
    loader.load().then((google) => {
      const blc = new google.maps.LatLng(47.19079322058432, 8.48274111042453);

      this.map = new google.maps.Map(el.querySelector('.contact__gmap'), {
        minZoom: 6,
        mapTypeControl: false,
        fullscreenControl: false,
        center: blc,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoom: 11,
        zoomControl: false,
        streetViewControl: false,
        restriction: {
          latLngBounds: SwitzerlandBounds,
        },
        styles: [{ featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#444444' }] }, { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] }, { featureType: 'landscape.man_made', elementType: 'geometry.stroke', stylers: [{ visibility: 'on' }] }, { featureType: 'landscape.man_made', elementType: 'labels.text', stylers: [{ visibility: 'off' }] }, { featureType: 'landscape.natural', elementType: 'labels.text', stylers: [{ visibility: 'off' }] }, { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] }, { featureType: 'road', elementType: 'all', stylers: [{ saturation: -100 }, { lightness: 45 }] }, { featureType: 'road', elementType: 'geometry.fill', stylers: [{ visibility: 'on' }] }, { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ visibility: 'on' }] }, { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] }, { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }, { featureType: 'road.local', elementType: 'geometry.fill', stylers: [{ visibility: 'on' }] }, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] }, { featureType: 'water', elementType: 'all', stylers: [{ color: '#000000' }, { visibility: 'on' }] }],
      });

      // eslint-disable-next-line no-undef
      const location = { lat: 47.19079322058432, lng: 8.48274111042453 };
      const customPin = '/assets/images/brandleadership_marker.png';
      // The marker, positioned at location
      // eslint-disable-next-line no-unused-vars
      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: customPin,
      });
    });
  }
}

Contact.className = 'Contact';
export default Contact;
