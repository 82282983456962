import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Teasers extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    // eslint-disable-next-line
    const swiper = new Swiper(slider, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      slidesPerView: 3,
      spaceBetween: 32,
      pagination: {
        el: '.three-teaser .swiper-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 16,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
    });
  }
}

Teasers.className = 'Teasers';
export default Teasers;
