import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Company extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger);
    const company = el.querySelector('.company__company');
    const companyLogo = el.querySelector('.company__company__logo');
    const companyClaim = el.querySelector('.company__company__claim');
    const companyText = el.querySelector('.company__company__text');
    const mm = gsap.matchMedia();

    // mobile anim
    mm.add('(max-width: 768px)', () => {
      gsap.set(companyLogo, {
        y: 0,
      });

      gsap.to(companyLogo, {
        y: 0,
        scrollTrigger: {
          markers: false,
          scrub: 0.5,
          delay: 0.5,
          // pin: true,
          trigger: el,
          start: 'top top',
          end: '100% top',
        },
      });

      gsap.to(companyClaim, {
        y: '-40vh',
        scrollTrigger: {
          markers: false,
          scrub: 0.5,
          delay: 0.5,
          pin: true,
          trigger: el,
          start: '5% top',
          end: '100% top',
        },
      });

      gsap.to(companyText, {
        y: '-40vh',
        scrollTrigger: {
          markers: false,
          scrub: 0.5,
          delay: 0.5,
          trigger: el,
          start: '5% top',
          end: '100% top',
        },
      });
    });

    // desktop anim
    mm.add('(min-width: 769px)', () => {
      gsap.set(company, {
        height: '120vh',
      });

      gsap.set(companyLogo, {
        y: 500,
      });

      gsap.to(companyLogo, {
        y: 0,
        scrollTrigger: {
          markers: false,
          scrub: 0.5,
          delay: 0.5,
          // pin: true,
          trigger: el,
          start: 'top top',
          end: '100% top',
        },
      });

      gsap.to(companyClaim, {
        y: '-80vh',
        scrollTrigger: {
          markers: false,
          scrub: 0.5,
          delay: 0.5,
          pin: true,
          trigger: el,
          start: '20% top',
          end: '100% top',
          onRefresh: (e) => {
            const progress = 1 - e.progress;
            if (progress >= 0.8) {
              companyClaim.style.lineHeight = '12rem';
            } else {
              companyClaim.style.lineHeight = '6rem';
            }
          },
          onUpdate: (self) => {
            const progress = 1 - self.progress;
            if (progress >= 0.8) companyClaim.style.lineHeight = `${progress * 8}rem`;
          },
        },
      });

      gsap.set(companyText, {
        y: '1000',
      });

      gsap.to(companyText, {
        y: '-700',
        scrollTrigger: {
          markers: false,
          scrub: 0.5,
          delay: 0.5,
          trigger: el,
          start: 'top top',
          end: 'bottom top',
          onRefresh: (e) => {
            const progress = 1 - e.progress;
            if (progress >= 0.5) {
              companyText.style.lineHeight = '6rem';
            } else {
              companyText.style.lineHeight = '4.4rem';
            }
          },
          onUpdate: (self) => {
            const progress = 1 - self.progress;
            if (progress >= 0.4) companyText.style.lineHeight = `${progress * 11}rem`;
          },
        },
      });
    });
  }
}

Company.className = 'Company';
export default Company;
