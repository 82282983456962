import Base from '../../_config/base';

class Filter extends Base {
  constructor(el) {
    super(el);

    this.filterTrigger = el.querySelector('.filter__trigger');
    this.filterToggle = el.querySelector('.filter__toggle');
    this.filterItems = el.querySelector('.filter__items');

    this.filterToggle.addEventListener('click', () => {
      this.filterItems.classList.toggle('d-none');
      this.filterTrigger.classList.toggle('expanded');
    });
  }
}

Filter.className = 'Filter';
export default Filter;
