import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Textblock extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger);
    const text = el.querySelector('.textblock__anim');
    const textblocks = [...el.querySelectorAll('.textBlock__text p')];
    const mm = gsap.matchMedia();

    // mobile animation
    mm.add('(max-width: 768px)', () => {
      if (el.querySelector('.textBlock__title') !== null) {
        ScrollTrigger.create({
          trigger: el,
          scrub: 0.5,
          delay: 0.5,
          markers: false,
          start: '-30% 70%',
          end: '40% 50%',
          onRefresh: (self) => {
            const progress = 1 - self.progress;
            if (progress >= 0.7) text.style.lineHeight = '4rem';

            if (self.progress === 1) {
              text.style.lineHeight = '3rem';
            }
          },
          onUpdate: (self) => {
            const progress = 1 - self.progress;
            if (progress >= 0.7) text.style.lineHeight = `${progress * 4}rem`;
          },
        });
      } else if (el.querySelector('.textBlock__text')) {
        if (textblocks.length > 1) {
          textblocks.forEach((textblock) => {
            gsap.set(textblock, {
              y: 100,
              alpha: 0,
            });
            gsap.to(textblock, {
              y: 0,
              alpha: 1,
              scrollTrigger: {
                markers: false,
                scrub: 0.5,
                delay: 0.5,
                trigger: textblock,
                start: 'top-=130% bottom',
                end: '50% bottom',
              },
            });
          });
        }

        gsap.set(el.querySelector('.textBlock__inner'), {
          y: 400,
        });
        gsap.to(el.querySelector('.textBlock__inner'), {
          y: 0,
          scrollTrigger: {
            markers: false,
            scrub: 0.5,
            delay: 0.5,
            trigger: el,
            start: 'top 70%',
            end: '100% 70%',
            onRefresh: (self) => {
              const progress = 1 - self.progress;
              if (progress >= 0.6) {
                text.style.lineHeight = '2.7rem';
              } else {
                gsap.set(el.querySelector('.textBlock__inner'), {
                  yPercent: 0,
                });
              }
              if (self.progress === 1) {
                text.style.lineHeight = '2.7rem';
              }
            },
            onUpdate: (self) => {
              const progress = 1 - self.progress;
              if (progress >= 0.6 && progress < 1) text.style.lineHeight = `${progress * 5}rem`;
            },
          },
        });
      }
    });

    // desktop animation
    mm.add('(min-width: 769px)', () => {
      if (el.querySelector('.textBlock__title') !== null) {
        ScrollTrigger.create({
          trigger: el,
          scrub: 0.5,
          delay: 0.5,
          markers: false,
          start: 'top 50%',
          end: 'bottom 50%',
          onRefresh: (self) => {
            const progress = 1 - self.progress;
            if (progress >= 0.8) {
              text.style.lineHeight = `${progress * 8}rem`;
            } else {
              text.style.lineHeight = '8rem';
            }
            // log current scroll position
            if (self.progress === 1) {
              text.style.lineHeight = '8rem';
            }
          },
          onUpdate: (self) => {
            const progress = 1 - self.progress;
            if (progress >= 0.8) text.style.lineHeight = `${progress * 8}rem`;
          },
        });
      } else if (el.querySelector('.textBlock__text')) {
        if (textblocks.length > 1) {
          textblocks.forEach((textblock) => {
            gsap.set(textblock, {
              y: 100,
              alpha: 0,
            });
            gsap.to(textblock, {
              y: 0,
              alpha: 1,
              delay: 5,
              scrollTrigger: {
                markers: false,
                scrub: 0.5,
                trigger: textblock,
                start: 'top-=50% 90%%',
                end: '90% 80%',
              },
            });
          });
        }
        gsap.set(el.querySelector('.textBlock__inner'), {
          y: 300,
        });
        gsap.to(el.querySelector('.textBlock__inner'), {
          y: 0,
          scrollTrigger: {
            markers: false,
            scrub: 0.5,
            delay: 0.5,
            trigger: el,
            start: 'top 50%',
            end: 'bottom 50%',
            onRefresh: (self) => {
              const progress = 1 - self.progress;
              if (progress >= 0.4) {
                text.style.lineHeight = '4.4rem';
              } else {
                text.style.lineHeight = '6rem';
                gsap.set(el.querySelector('.textBlock__inner'), {
                  y: 0,
                });
              }
              if (self.progress === 1) {
                text.style.lineHeight = '4.4rem';
              }
            },
            onUpdate: (self) => {
              const progress = 1 - self.progress;
              if (progress >= 0.65 && progress < 1) text.style.lineHeight = `${progress * 6}rem`;
            },
          },
        });
      }
    });
  }
}

Textblock.className = 'Textblock';
export default Textblock;
