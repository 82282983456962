import Base from '../../_config/base';

class Button extends Base {
  constructor(el) {
    super(el);
    el.querySelector('.button').onclick = () => el.classList.add('active');
  }
}

Button.className = 'Button';
export default Button;
