import { getCookie } from '../../_config/utils';
import Base from '../../_config/base';

class Dialogues extends Base {
  constructor(el) {
    super(el);
    this.dialogues = el;
    this.close = el.querySelector('.dialogues__close');

    const setCookie = (cname, cvalue, exdays) => {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${cname}=${cvalue}; ${expires}`;
    };

    if (getCookie('dialogueClosed') === 'set') {
      this.dialogues.classList.add('d-none');
    }

    this.close.onclick = () => {
      setCookie('dialogueClosed', 'set', 1);
      this.dialogues.classList.add('d-none');
    };
  }
}

Dialogues.className = 'Dialogues';
export default Dialogues;
