import Button from './01-atoms/button/button';
import Chapter from './01-atoms/chapter/chapter';
import Scrollsmoother from './01-atoms/scrollsmoother/scrollsmoother';
// import Attachment from './01-atoms/inputs/attachment';
import Video from './01-atoms/video/video';
// import Range from './01-atoms/inputs/range';
// import Select from './01-atoms/inputs/select';
// import Share from './02-molecules/share/share';
// import Accordion from './02-molecules/accordion/accordion';
// import Alerts from './02-molecules/alerts/alerts';
// import Popup from './02-molecules/popup/popup';
import Slider from './02-molecules/slider/slider';
import Teasers from './02-molecules/threeTeaser/teasers';
import Filter from './02-molecules/filter/filter';
import Inverted from './02-molecules/inverted/inverted';
import Textblock from './02-molecules/textblock/textblock';
import Dialogues from './02-molecules/dialogues/dialogues';
import Companyoverview from './02-molecules/companyOverview/companyOverview';
import Experten from './02-molecules/experten/experten';
import Company from './02-molecules/company/company';
import Contact from './02-molecules/contact/contact';
import Header from './03-organisms/header/header';
import Magazin from './03-organisms/magazin/magazin';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Button = Button.init();
  Component.Chapter = Chapter.init();
  Component.Contact = Contact.init();
  Component.Experten = Experten.init();
  Component.Company = Company.init();
  Component.Video = Video.init();
  Component.Companyoverview = Companyoverview.init();
  // Component.Range = Range.init();
  Component.Dialogues = Dialogues.init();
  Component.Inverted = Inverted.init();
  Component.Textblock = Textblock.init();
  // Component.Share = Share.init();
  // Component.Accordion = Accordion.init();
  // Component.Alerts = Alerts.init();
  // Component.Popup = Popup.init();
  Component.Slider = Slider.init();
  // Component.Attachment = Attachment.init();
  // Component.Select = Select.init();
  Component.Header = Header.init();
  Component.Teasers = Teasers.init();
  Component.Filter = Filter.init();
  Component.Magazin = Magazin.init();
  Component.Scrollsmoother = Scrollsmoother.init();
});
