import Base from '../../_config/base';

class Magazin extends Base {
  constructor(el) {
    super(el);
    const magazinForm = el.querySelector('.magazin__form');
    const popupBtn = el.querySelector('.magazin__popup');
    const closeBtnAll = [...el.querySelectorAll('.magazin__form__close')];
    const submitButton = el.querySelector('.magazin__form__submit');
    const formSuccess = el.querySelector('.form__sucess');
    const formError = el.querySelector('.form__error');
    const formWrapper = document.querySelector('.form__wrapper');
    const formular = el.querySelector('.form');
    this.body = document.getElementById('body');

    // Open popup onclick
    popupBtn.onclick = () => {
      magazinForm.classList.toggle('magazin__form--open');
      this.body.classList.toggle('overflow-hidden');
    };

    // Close popup onclick
    closeBtnAll.forEach((closeBtn) => {
      closeBtn.onclick = () => {
        magazinForm.classList.toggle('magazin__form--open');
        this.body.classList.toggle('overflow-hidden');
      };
    });

    const serialize = (form) => {
      // Setup our serialized data
      const serialized = [];
      // Loop through each field in the form
      for (let i = 0; i < form.elements.length; i += 1) {
        const field = form.elements[i];
        // eslint-disable-next-line no-continue
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
        // If a multi-select, get all selections
        if (field.type === 'select-multiple') {
          for (let n = 0; n < field.options.length; n += 1) {
            // eslint-disable-next-line no-continue
            if (!field.options[n].selected) continue;
            serialized.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.options[n].value)}`);
          }
        } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
          serialized.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`);
        }
      }
      return serialized.join('&');
    };

    // Ajax call to submit the form and show the success page
    const submitContactForm = (ev) => {
      ev.preventDefault();
      submitButton.disabled = true;

      const xhr = new XMLHttpRequest();
      xhr.open('POST', '', true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      xhr.onload = () => {
        if (xhr.status === 200) {
          // formWrapper.classList.add('hidden');
          formSuccess.classList.remove('d-none');
          formWrapper.classList.add('d-none');
        } else {
          formError.classList.remove('d-none');
          formWrapper.classList.add('d-none');
        }
      };
      xhr.send(serialize(ev.target));
    };

    formular.addEventListener('submit', submitContactForm);
  }
}

Magazin.className = 'Magazin';
export default Magazin;
