import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';
import Base from '../../_config/base';

class Experten extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger);

    const expertAll = [...el.querySelectorAll('.experten__item')];
    const allClosePopups = [...el.querySelectorAll('.experten__popup-buttons-close')];
    const sliderWrapper = el.querySelector('.experten__popup__wrapper');
    const slider = el.querySelector('.experten__popup__swiper');
    const mm = gsap.matchMedia();

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, EffectFade],
      centeredSlides: true,
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);

    // mobile animation
    mm.add('(max-width: 768px)', () => {
      const animationDistance = 500;

      expertAll.forEach((expert) => {
        expert.onclick = () => {
          sliderWrapper.classList.toggle('experten__popup__wrapper--active');
          swiper.slideTo(expert.dataset.index);
        };

        gsap.set(expert, {
          y: animationDistance,
        });

        gsap.to(expert, {
          y: 0,
          scrollTrigger: {
            trigger: expert,
            scrub: 0.5,
            delay: 0.5,
            pin: false,
            markers: false,
            start: `top-=${animationDistance} 80%`,
            end: `bottom-=${animationDistance} 80%`,
          },
        });
      });

      allClosePopups.forEach((closePopup) => {
        closePopup.onclick = () => {
          sliderWrapper.classList.remove('experten__popup__wrapper--active');
        };
      });
    });

    // desktop animation
    mm.add('(min-width: 769px)', () => {
      expertAll.forEach((expert) => {
        // random number between 300 and 600
        const animationDistance = Math.floor(Math.random() * 200) + 100;

        // random number between 60 and 80
        const random = 100;

        expert.onclick = () => {
          sliderWrapper.classList.toggle('experten__popup__wrapper--active');
          swiper.slideTo(expert.dataset.index);
        };

        gsap.set(expert, {
          y: animationDistance,
        });

        gsap.to(expert, {
          y: 0,
          scrollTrigger: {
            trigger: expert,
            scrub: 0.5,
            delay: 0.5,
            pin: false,
            markers: false,
            start: `top-=${animationDistance} ${random}%`,
            end: `bottom-=${animationDistance} ${random}%`,
          },
        });
      });

      allClosePopups.forEach((closePopup) => {
        closePopup.onclick = () => {
          sliderWrapper.classList.remove('experten__popup__wrapper--active');
        };
      });
    });
  }
}

Experten.className = 'Experten';
export default Experten;
