import Base from '../../_config/base';

class Chapter extends Base {
  constructor(el) {
    super(el);
    const allChapters = [...document.querySelectorAll('.chapter')];
    const allChapterPoints = [...document.querySelectorAll('.chapterElement__point')];

    if (allChapters.length <= 1) return;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allChapterPoints.length; i++) {
      const point = allChapterPoints[i];

      point.addEventListener('click', () => {
        allChapters[i].scrollIntoView({ behavior: 'smooth' });
      });
    }

    // if scroll into chapter, change active point
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          allChapterPoints.forEach((element) => {
            element.classList.remove('chapterElement__point-active');
          });

          allChapterPoints[allChapters.indexOf(entry.target)].classList.add('chapterElement__point-active');
        }
      });
    }, options);

    allChapters.forEach((chapter) => {
      observer.observe(chapter);
    });
  }
}

Chapter.className = 'Chapter';
export default Chapter;
