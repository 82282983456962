import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Base from '../../_config/base';

class Video extends Base {
  constructor(el) {
    super(el);
    gsap.registerPlugin(ScrollTrigger);

    const mm = gsap.matchMedia();

    mm.add('(max-width: 768px)', () => {
      // this setup code only runs when viewport is at least 800px wide
      gsap.set(el, {
        yPercent: -100,
      });

      gsap.to(el, {
        yPercent: 0,
        scrollTrigger: {
          trigger: el,
          scrub: true,
          markers: false,
          start: 'top-=50% top',
          end: 'bottom+=100% top',
        },
      });
    });

    mm.add('(min-width: 769px)', () => {
      // this setup code only runs when viewport is at least 800px wide

      gsap.set(el, {
        y: -150,
      });

      gsap.to(el, {
        y: 500,
        scrollTrigger: {
          trigger: el,
          scrub: true,
          pin: false,
          markers: false,
          start: 'top-=10% top',
          end: 'bottom+=100% top',
        },
      });
    });
  }
}

Video.className = 'Video';
export default Video;
